import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getUsers, logout } from '../../helpers/firebase';
import { useNavigate } from 'react-router-dom';
import Table from './Table/';

const ManualEvent = (props) => {
  const [user, loading, error] = useAuthState(auth);
  const [clubs, setClubs] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user || error) {
      return navigate('/');
    }

    if (user) {
      getUsers()
        .then((data) => {
          if (data.length > 0) {
            setClubs(data);
          }
        })
        .catch((err) => {
          logout();
          navigate('/');
        });
    }
  }, [user, loading]);

  // loading spinner
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Table clubs={clubs} />
    </div>
  );
};

export default ManualEvent;
