import React, { useEffect, useState } from 'react';
import {
  addTeamPlayers,
  deleteTeamPlayers,
  getTeamPlayers
} from '../../helpers/firebase';
import '../../styles/Table.css';

const PLAYER_ROLES = ['striker', 'midfielder', 'defender', 'goalkeeper'];
const PLAYER_POSITIONS = ['left', 'center', 'right'];
const TeamPlayers = ({ club, teamName }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [processedCsvFile, setProcessedCsvFile] = useState({});
  const [players, setPlayers] = useState([]);

  const processCsvFile = () => {
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;

      // split the text into lines multiple cases and remove header
      const lines = text.split(/\r\n|\n/).slice(1);

      const playerData = [];
      lines.forEach((line) => {
        // split each line into values by comma or semicolon
        const values = line.split(/,|;/);
        const [name, email, tShirtNumber, role, ppos] = values;

        if (
          (name && role && ppos) ||
          (name && role && role.toLowerCase() === 'goalkeeper')
        ) {
          const roleIndex = PLAYER_ROLES.indexOf(
            role.replace(/[\s|,|.]+/g, '').toLowerCase()
          );

          const pposIndex = PLAYER_POSITIONS.indexOf(
            ppos.replace(/[\s|,|.]+/g, '').toLowerCase()
          );

          let playerRow = {
            name,
            email: email || '',
            tShirtNumber: tShirtNumber || null,
            role: roleIndex,
            ppos: pposIndex,
            position: [roleIndex, pposIndex]
          };
          playerData.push(playerRow);
        }
      });

      setProcessedCsvFile(playerData);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    fetchPlayers();
  }, [club]);

  useEffect(() => {
    if (csvFile) {
      processCsvFile();
    }
  }, [csvFile]);

  useEffect(() => {
    if (processedCsvFile.length > 0) {
      addTeamPlayers(club.id, teamName, processedCsvFile).then(() => {
        setProcessedCsvFile([]);
        setCsvFile(null);
        fetchPlayers();
      });
    }
  }, [processedCsvFile.length]);

  const fetchPlayers = async () => {
    await getTeamPlayers(teamName, club.id)
      .then((data) => {
        setPlayers(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const onDeleteAllPlayers = async (index, obj) => {
    let answer = window.confirm(`Delete All Players ?`);
    if (answer) {
      await deleteTeamPlayers(club.id, teamName);
      fetchPlayers();
    }
  };

  return (
    <li>
      <p>{club.data.name}</p>
      {players ? (
        <div>
          {players.map((player, index) => {
            return (
              <div className="row margin-vertical-10" key={index}>
                <div className="row">
                  <span>{index + 1}.</span>
                  <span className="email">{player.email || player.name}</span>
                  {
                    // <span className="detail">{player.tShirtNumber}</span>
                    // <span className="detail">{player.role}</span>
                    // <span className="detail">{player.position}</span>
                  }
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No Players</p>
      )}

      {
        // <button
        //   className="list-item-12"
        //   onClick={() => {
        //     addTag ? submitTag('add') : setAddTag(true);
        //   }}
        //   disabled={tagEdit}
        // >
        //   {addTag ? 'Submit' : 'Add'} Player
        // </button>
      }
      <input
        className="custom-file-input"
        type="file"
        accept=".csv"
        onChange={(e) => setCsvFile(e.target.files[0])}
        id={`csvFile-${club.id}`}
      />
      <label className="custom-file-label" htmlFor={`csvFile-${club.id}`}>
        Select a file to add players
      </label>

      <button
        className="list-item-12"
        onClick={onDeleteAllPlayers}
        disabled={!players}
      >
        Delete All Players
      </button>
    </li>
  );
};

export default TeamPlayers;
