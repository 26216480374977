import React, { useEffect, useState } from 'react';
import { generateGeneralStatsForReport } from '../../helpers';
import {
  addGeneralStatusStats,
  addReportStatCollection,
  getPlayersByTeam,
  getTeamGamesWithoutReports,
  getTeamTagsCollection
} from '../../helpers/firebase';

const ActiveTeamSelect = ({ activeTeam }) => {
  const [games, setGames] = useState([]);
  const [activeEvent, setActiveEvent] = useState(null);
  const [activePlayer, setActivePlayer] = useState(null);
  const [playersState, setPlayersState] = useState({});
  const [sessionMinutes, setSessionMinutes] = useState('');
  const [eventPlayers, setEventPlayers] = useState({});
  const [macIdToPlayerId, setMacIdToPlayerId] = useState({});
  const [jsonFile, setJsonFile] = useState(null);
  const [jsonReport, setJsonReport] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);

  useEffect(() => {
    if (activeTeam) {
      getTeamGamesWithoutReports(activeTeam.clubName, activeTeam.id)
        .then(setGames)
        .catch((err) => {
          console.log('err', err);
          setGames([]);
        });

      getPlayersByTeam(activeTeam.clubName, activeTeam.id)
        .then((players) => {
          setEventPlayers(players);

          getTeamTagsCollection(activeTeam.clubName, activeTeam.id)
            .then((subCol) => {
              if (subCol.length > 0) {
                const tags = subCol.find((e) => e.tags) || {};
                const macIdToPlayerId = {};
                Object.keys(tags.tags || {}).forEach((macId) => {
                  const tagId = tags.tags[macId];
                  const playerId = Object.values(players).find(
                    (e) => e.tag === tagId
                  )?.id;
                  if (playerId) {
                    macIdToPlayerId[macId] = playerId;
                  }
                });

                setMacIdToPlayerId(macIdToPlayerId);
              }
            })
            .catch((err) => {
              console.log('err', err);
            });
        })
        .catch((err) => {
          console.log('err', err);
          setEventPlayers({});
        });
    }
  }, [activeTeam, activeEvent]);

  useEffect(() => {
    if (jsonFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = replaceJsonFileMacIds(e.target.result);

        // clear input file
        setJsonReport(data);
        if ('stats' in data) {
          setSessionMinutes(Number(data.stats.team.fullSession.duration));
        }
      };
      reader.readAsText(jsonFile);
    }
  }, [jsonFile, macIdToPlayerId]);

  useEffect(() => {
    if (jsonReport) {
      processFileReport();
    }
  }, [jsonReport]);

  const processFileReport = async () => {
    // check keys in data if they have type of object
    if (!('stats' in jsonReport)) {
      return alert('Invalid report');
    }

    setReportLoading(true);
    const report = { stats: jsonReport.stats };

    for (let key in report) {
      if (typeof report[key] === 'object') {
        await addReportStatCollection(
          activeTeam.clubName,
          activeTeam.id,
          activeEvent.id,
          key,
          report[key]
        );
      }
    }

    const generalStats = generateGeneralStatsForReport(
      activeEvent.type === 'match',
      activeEvent.date,
      sessionMinutes
    );

    await addGeneralStatusStats(
      activeTeam.clubName,
      activeTeam.id,
      activeEvent.id,
      generalStats
    );
    setReportLoading(false);
    setJsonReport(null);
    setJsonFile(null);
    setActiveEvent(null);
  };

  // const activeEventPlayersMemo = useMemo(() => {
  //   if (!activeEvent || !activeEvent?.preparation) return [];
  //   const isMatch = activeEvent.type === 'match';
  //   let players = [];
  //   if (isMatch) {
  //     players =
  //       activeEvent.preparation?.playersInPitch.map(
  //         (player) => player.playerId
  //       ) || [];
  //   } else {
  //     players = activeEvent.preparation?.totalPlayerIds || [];
  //   }

  //   return players;
  // }, [activeEvent]);

  // const isBtnEnabledMemo = useMemo(() => {
  //   let count = 0;
  //   if (
  //     Object.keys(playersState || {}).length !== activeEventPlayersMemo.length
  //   )
  //     return false;

  //   Object.keys(playersState).forEach((playerId) => {
  //     if (
  //       Object.keys(playersState[playerId] || {}).length ===
  //       PLAYER_FORM_FIELDS.length
  //     ) {
  //       count++;
  //     }
  //   });

  //   if (
  //     count === Object.keys(playersState).length &&
  //     !isNaN(sessionMinutes) &&
  //     sessionMinutes > 0
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [playersState, sessionMinutes, activeEventPlayersMemo]);

  const replaceJsonFileMacIds = (text) => {
    const regex = /[0-9A-Fa-f]{2}(:[0-9A-Fa-f]{2}){3}/g;
    const unmatchedMacIds = [];
    const replacedText = text.replace(regex, (match) => {
      if (match in macIdToPlayerId) {
        return macIdToPlayerId[match];
      }
      unmatchedMacIds.push(match);
      return match;
    });

    const parsedText = JSON.parse(replacedText);

    unmatchedMacIds.forEach((macId) => {
      delete parsedText.stats.players[macId];
    });
    return parsedText;
  };

  // const toggleActivePlayer = (playerId) => {
  //   if (activePlayer === playerId) {
  //     setActivePlayer(null);
  //   } else {
  //     setActivePlayer(playerId);
  //   }
  // };

  // const onSubmit = async () => {
  //   const teamData = [];
  //   const playerData = {};
  //   const playerDataSeries = {};
  //   Object.keys(playersState).forEach((player) => {
  //     playerData[player] = reportStatsWrapperPlayer(
  //       playersState[player],
  //       activeEvent.date
  //     );
  //     playerDataSeries[player] = reportSeriesWrapper(
  //       playersState[player],
  //       activeEvent.date
  //     );

  //     teamData.push(playersState[player]);
  //   });

  //   const { teamSeries, teamStats } = reportStatsWrapperTeam(
  //     teamData,
  //     activeEvent.date
  //   );

  //   const payloadStats = {
  //     players: playerData,
  //     team: teamStats
  //   };

  //   const payloadSeries = {
  //     players: playerData,
  //     team: teamSeries
  //   };

  //   const payload = {
  //     series: payloadSeries,
  //     stats: payloadStats
  //   };

  //   const generalStats = generateGeneralStatsForReport(
  //     activeEvent.type === 'match',
  //     activeEvent.date,
  //     sessionMinutes
  //   );

  //   await addReportCollection(
  //     activeTeam.clubName,
  //     activeTeam.id,
  //     activeEvent.id,
  //     payload,
  //     generalStats
  //   );

  //   setActiveEvent(null);
  //   setPlayersState({});
  //   setActivePlayer(null);
  //   setSessionMinutes('');
  // };

  // const renderPlayerFormFields = (player) => {
  //   if (!activePlayer || activePlayer !== player) return null;

  //   return (
  //     <EventForm
  //       formData={playersState[player]}
  //       setValues={(key, val) => {
  //         if (val === '' || !val) {
  //           let tempState = { ...(playersState[player] || {}) };
  //           if (tempState[key]) {
  //             delete tempState[key];
  //             return setPlayersState({
  //               ...playersState,
  //               [player]: {
  //                 ...tempState
  //               }
  //             });
  //           }
  //         }
  //         setPlayersState({
  //           ...playersState,
  //           [player]: {
  //             ...playersState[player],
  //             [key]: val
  //           }
  //         });
  //       }}
  //     />
  //   );
  // };

  // const renderPlayers = () => {
  //   if (!activeEvent.preparation) {
  //     return (
  //       <ul className="danger-bg">
  //         <span>No players selected</span>
  //       </ul>
  //     );
  //   }

  //   return (
  //     <ul>
  //       {activeEventPlayersMemo
  //         .filter((player) => eventPlayers[player])
  //         .map((player, index) => {
  //           return (
  //             <li
  //               key={index}
  //               className="team-name"
  //               style={
  //                 Object.keys(playersState[player] || {}).length ===
  //                 PLAYER_FORM_FIELDS.length
  //                   ? { backgroundColor: '#26c281' }
  //                   : {}
  //               }
  //             >
  //               <p onClick={() => toggleActivePlayer(player)}>
  //                 {eventPlayers[player].name}
  //                 <br />
  //                 <span>Tag Number: {eventPlayers[player].tag}</span>
  //               </p>

  //               {renderPlayerFormFields(player)}
  //             </li>
  //           );
  //         })}
  //     </ul>
  //   );
  // };

  const renderLoadingSpinner = () => {
    return (
      <div className="overlay">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  if (activeEvent) {
    const isEventFinished = !!(activeEvent.status && activeEvent.readerList);

    return (
      <div>
        {reportLoading && renderLoadingSpinner()}
        <div className="wrapper">
          <p>
            Event type: <b>{activeEvent.type}</b>
          </p>
          <p>ID: {activeEvent.id}</p>
          <p className="btn-close button" onClick={() => setActiveEvent(null)}>
            Back
          </p>
          {
            // !isEventFinished && renderPlayers()
          }

          {
            //   !isEventFinished && (
            //   <div
            //     style={{
            //       display: 'flex',
            //       flexDirection: 'column',
            //       padding: 7
            //     }}
            //   >
            //     <input
            //       type="number"
            //       className="login__textBox"
            //       placeholder="Session Minutes"
            //       value={sessionMinutes}
            //       onChange={(e) => setSessionMinutes(e.target.value)}
            //     />
            //     <button
            //       type="submit"
            //       className="button"
            //       style={
            //         !isBtnEnabledMemo
            //           ? {
            //               backgroundColor: '#e0e0e0',
            //               cursor: 'default'
            //             }
            //           : {}
            //       }
            //       disabled={!isBtnEnabledMemo || !activeEvent.preparation}
            //       onClick={onSubmit}
            //     >
            //       <span className="button__text">Create</span>
            //     </button>
            //   </div>
            // )
          }

          <input
            className="custom-file-input"
            type="file"
            accept=".json"
            onChange={(e) => setJsonFile(e.target.files[0])}
            id="csvFile"
          />
          <label className="button btn-report" htmlFor="csvFile">
            Select a file to {isEventFinished ? 'overwrite' : 'add'} report
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className="wrapper">
      <p>Team {activeTeam.data.name}</p>
      {games.length > 0 ? (
        <ul>
          {games.map((game) => {
            return (
              <li
                key={game.id}
                className="team-name"
                onClick={() => setActiveEvent(game)}
              >
                <p>{game.id}</p>
                <h4>{game.type}</h4>
                <p>
                  {game.date} - {game.startTime}
                </p>
              </li>
            );
          })}
        </ul>
      ) : (
        <p>No Games</p>
      )}
    </div>
  );
};

export default ActiveTeamSelect;
