import React, { useEffect, useState } from 'react';
import {
  getTeamTagsCollection,
  updateTags,
  createInitialTag
} from '../../helpers/firebase';
import '../../styles/Table.css';

const TeamTags = ({ club, teamName }) => {
  const [tags, setTags] = useState(null);
  const [tagEdit, setTagEdit] = useState(null);
  const [addTag, setAddTag] = useState(false);
  const [newTagData, setNewTagData] = useState({
    mac_address: '',
    player_id: ''
  });
  const [editTagData, setEditTagData] = useState({
    mac_address: '',
    player_id: ''
  });
  const [csvFile, setCsvFile] = useState(null);
  const [processedCsvFile, setProcessedCsvFile] = useState({});

  const processCsvFile = () => {
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;

      // split the text into lines multiple cases and remove header
      const lines = text.split(/\r\n|\n/).slice(1);

      // regex to check if text has a mac address with 8 characters
      const macRegex = /^[0-9A-Fa-f]{2}(:[0-9A-Fa-f]{2}){3}$/;
      const tagMapping = {};
      lines.forEach((line) => {
        // split each line into values by comma or semicolon
        const values = line.split(/,|;/);
        const mac = values[0];
        const tagId = values[1];

        if (macRegex.test(mac) && tagId) {
          // create object where key is mac address and value is tag id
          tagMapping[mac] = tagId;
        }
      });

      setProcessedCsvFile(tagMapping);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    fetchTags();
  }, [club]);

  useEffect(() => {
    if (csvFile) {
      processCsvFile();
    }
  }, [csvFile]);

  useEffect(() => {
    if (Object.keys(processedCsvFile).length > 0) {
      submitTag('add', true);
    }
  }, [Object.keys(processedCsvFile).length]);

  const fetchTags = async () => {
    await getTeamTagsCollection(teamName, club.id)
      .then((subCol) => {
        if (subCol.length > 0) {
          const tags = subCol.find((e) => e.tags) || {};
          setTags(tags.tags);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const submitTag = async (type, isFromFile = false) => {
    const tagData = type === 'add' ? newTagData : editTagData;
    const newArray = isFromFile
      ? { ...tags, ...processedCsvFile }
      : { ...tags, [tagData.mac_address]: tagData.player_id };
    const func =
      tags && Object.values(tags).length > 0 ? updateTags : createInitialTag;
    await func(teamName, club.id, newArray)
      .then(fetchTags)
      .catch((err) => {
        console.log('ERROR: ', err);
      });
    type === 'add' ? setAddTag(false) : setTagEdit(null);
    if (isFromFile) {
      setCsvFile(null);
      setProcessedCsvFile({});
      // clear input
      document.getElementById(`csvFile-${club.id}`).value = '';
    }
  };

  const cancelNewTag = () => {
    setNewTagData({
      mac_address: '',
      player_id: ''
    });
    setAddTag(false);
  };

  const onEditTag = (index, mac) => {
    setEditTagData({ mac_address: mac[0], player_id: mac[1] });
    setTagEdit(index);
  };

  const cancelEditTag = () => {
    setEditTagData({
      mac_address: '',
      player_id: ''
    });
    setTagEdit(null);
  };

  const onDeleteTag = async (index, obj) => {
    let answer = window.confirm(`Delete player ${obj[1]} ?`);
    if (answer) {
      delete tags[obj[0]];
      await updateTags(teamName, club.id, tags)
        .then((res) => {
          fetchTags();
        })
        .catch((err) => {
          console.log('ERROR: ', err);
        });
    } else {
      console.log('Not Updated');
    }
  };

  const onDeleteAllTags = async (index, obj) => {
    let answer = window.confirm(`Delete All Tags ?`);
    if (answer) {
      await updateTags(teamName, club.id, {})
        .then((res) => {
          fetchTags();
        })
        .catch((err) => {
          console.log('ERROR: ', err);
        });
    }
  };

  const handleChange = (name, value, type = 'add') => {
    if (type === 'edit') {
      setEditTagData({ ...editTagData, [name]: value });
    }
    if (type === 'add') {
      setNewTagData({ ...newTagData, [name]: value });
    }
  };

  return (
    <li>
      <p>{club.data.name}</p>
      {tags ? (
        <div>
          {Object.entries(tags).map((obj, index) => {
            return (
              <div className="row margin-vertical-10" key={obj[0]}>
                <div className="row">
                  <label htmlFor="mac-address" className="label">
                    Mac
                  </label>
                  <input
                    type="text"
                    name="mac-address"
                    id="mac-address"
                    value={tagEdit !== index ? obj[0] : editTagData.mac_address}
                    className="input"
                    disabled
                    onChange={(e) =>
                      handleChange('mac_address', e.target.value, 'edit')
                    }
                  />
                </div>
                <div className="row">
                  <label htmlFor="player-id" className="label">
                    Id
                  </label>
                  <input
                    type="text"
                    name="player-id"
                    id="player-id"
                    value={tagEdit !== index ? obj[1] : editTagData.player_id}
                    className="input"
                    disabled={tagEdit !== index}
                    onChange={(e) =>
                      handleChange('player_id', e.target.value, 'edit')
                    }
                  />
                </div>
                {tagEdit === index ? (
                  <div className="row">
                    <div className="btn_svg" onClick={() => submitTag('edit')}>
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.6667 10L12.5 19.1667L8.33333 15"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="btn_svg" onClick={cancelEditTag}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="currentColor"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div
                      onClick={() => onEditTag(index, obj)}
                      className="btn_svg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="currentColor"
                          d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3a1 1 0 0 0-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a1 1 0 0 0 0-1.41l-2.34-2.34a.982.982 0 0 0-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={() => onDeleteTag(index, obj)}
                      className="btn_svg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="currentColor"
                          d="M6 19a2.006 2.006 0 0 0 2 2h8a2.006 2.006 0 0 0 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p>No Tags</p>
      )}
      {addTag && (
        <div className="row margin-vertical-10">
          <div className="row">
            <label htmlFor="mac-address" className="label">
              Mac
            </label>
            <input
              type="text"
              name="mac-address"
              id="mac-address"
              value={newTagData.mac}
              className="input"
              onChange={(e) => handleChange('mac_address', e.target.value)}
            />
          </div>
          <div className="row">
            <label htmlFor="player-id" className="label">
              Id
            </label>
            <input
              type="text"
              name="player-id"
              id="player-id"
              value={newTagData.num}
              className="input"
              onChange={(e) => handleChange('player_id', e.target.value)}
            />
          </div>
          <div className="btn_svg" onClick={cancelNewTag}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
            </svg>
          </div>
        </div>
      )}

      <button
        className="list-item-12"
        onClick={() => {
          addTag ? submitTag('add') : setAddTag(true);
        }}
        disabled={tagEdit}
      >
        {addTag ? 'Submit' : 'Add'} Tag
      </button>
      <input
        className="custom-file-input"
        type="file"
        accept=".csv"
        onChange={(e) => setCsvFile(e.target.files[0])}
        id={`csvFile-${club.id}`}
      />
      <label className="custom-file-label" htmlFor={`csvFile-${club.id}`}>
        Select a file to add tags
      </label>
      <button
        className="list-item-12"
        onClick={onDeleteAllTags}
        disabled={!tags}
      >
        Delete All Tags
      </button>
    </li>
  );
};

export default TeamTags;
