import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUsers, logout } from "../../helpers/firebase";
import PremiumTable from "./PremiumTable";

const Tags = () => {
  const [user, loading, error] = useAuthState(auth);
  const [clubs, setClubs] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user || error) {
      return navigate("/");
    }

    if (user) {
      getUsers()
        .then((data) => {
          if (data.length > 0) {
            setClubs(data);
          }
        })
        .catch((err) => {
          logout();
          navigate("/");
        });
    }
  }, [user, loading]);
  return (
    <div>
      <PremiumTable clubs={clubs} />
    </div>
  );
};

export default Tags;
