import React, { useEffect, useState } from 'react';
import { formatDate } from '../../helpers/dateHelpers';

const User = ({ player, premium, userToUpdate }) => {
  let initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() + 1);
  const [expand, setExpand] = useState(false);
  const [activePremiumTimeStamp, setActivePremiumTimeStamp] =
    useState(initialDate);
  const [activeFreemiumTimeStamp, setActiveFreemiumTimeStamp] =
    useState(initialDate);
  const [isPremium, setIsPremium] = useState(false);
  const [isFreemium, setIsFreemium] = useState(false);

  let name = player.name;
  if (!player.name) {
    if (player.first_name && player.last_name) {
      name = `${player.first_name} ${player.last_name}`;
    } else if (player.first_name) {
      name = player.first_name;
    } else if (player.last_name) {
      name = player.last_name;
    } else {
      name = 'Name not provided';
    }
  }

  useEffect(() => {
    if (premium?.premium_until?.seconds) {
      const status =
        new Date().getTime() < premium.premium_until.seconds * 1000;
      if (status) {
        setIsPremium(true);
        setActivePremiumTimeStamp(premium.premium_until.seconds * 1000);
      }
    }

    if (premium?.freemium_until?.seconds) {
      const status =
        new Date().getTime() < premium.freemium_until.seconds * 1000;
      if (status) {
        setIsFreemium(true);
        setActiveFreemiumTimeStamp(premium.freemium_until.seconds * 1000);
      }
    }
  }, [expand]);

  return (
    <li>
      <p className="paragraph">{name}</p>
      {isPremium && (
        <p className="paragraph golden">
          Premium until {formatDate(activePremiumTimeStamp)}
        </p>
      )}

      {isFreemium && (
        <p className="paragraph red">
          Freemium until {formatDate(activeFreemiumTimeStamp)}
        </p>
      )}
      <button
        style={{
          position: 'absolute',
          right: '10px'
        }}
        onClick={() => setExpand(!expand)}
      >
        {expand ? '-' : '+'}
      </button>
      {expand && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p className="paragraph">Set player as premium</p>
            <input
              type="date"
              name="datepicker"
              id="datepicker"
              value={formatDate(activePremiumTimeStamp, 'y-m-d')}
              onChange={(e) => {
                setActivePremiumTimeStamp(new Date(e.target.value).getTime());
              }}
            />
            <br />
            <span
              className="paragraph golden"
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={() =>
                userToUpdate(player, activePremiumTimeStamp, premium)
              }
            >
              + update
            </span>
            {isPremium && (
              <p
                className="paragraph red"
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={() => userToUpdate(player, false, premium)}
              >
                - remove premium
              </p>
            )}
          </div>

          <div>
            <p className="paragraph">Set player as freemium</p>
            <input
              type="date"
              name="datepicker"
              id="datepicker"
              value={formatDate(activeFreemiumTimeStamp, 'y-m-d')}
              onChange={(e) => {
                setActiveFreemiumTimeStamp(new Date(e.target.value).getTime());
              }}
            />
            <br />
            <span
              className="paragraph golden"
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={() =>
                userToUpdate(
                  player,
                  activeFreemiumTimeStamp,
                  premium,
                  'freemium_until'
                )
              }
            >
              + update
            </span>
            {isFreemium && (
              <p
                className="paragraph red"
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={() =>
                  userToUpdate(player, false, premium, 'freemium_until')
                }
              >
                - remove freemium
              </p>
            )}
          </div>
        </div>
      )}
    </li>
  );
};

export default User;
