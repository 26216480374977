export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function formatDate(timestamp, order) {
  let prevDate = new Date(timestamp).getDate();
  let date = prevDate < 10 ? `0${prevDate}` : prevDate;
  let prevMonth = new Date(timestamp).getMonth() + 1;
  let month = prevMonth < 10 ? `0${prevMonth}` : prevMonth;
  let year = new Date(timestamp).getFullYear();

  switch (order) {
    case `d-m-y`:
      return `${date}-${month}-${year}`;
    case `y-m-d`:
      return `${year}-${month}-${date}`;
    default:
      return `${date}-${month}-${year}`;
  }
}

// parse date to timestamp
export const parseDateToTimestamp = (date) => {
  return new Date(date).getTime();
};
