import React, { useEffect, useState } from 'react';
import { getTeams } from '../../helpers/firebase';
import TeamPlayers from './TeamPlayers';

const ExpandedPlayerColumn = ({ teamName, isExpanded }) => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setLoading(true);
      getTeams(teamName)
        .then((teams) => {
          setTeams(teams);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isExpanded]);

  if (loading) {
    return (
      <ul>
        <div>Loading...</div>
      </ul>
    );
  }

  if (teams.length === 0) {
    return (
      <ul className="danger-bg">
        <span>No Teams</span>
      </ul>
    );
  }
  return (
    <ul>
      {teams.map((it) => {
        return <TeamPlayers key={it.id} club={it} teamName={teamName} />;
      })}
    </ul>
  );
};

export default ExpandedPlayerColumn;
