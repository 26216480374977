import React, { useState } from "react";

import "../Table/Table.css";
import "./TagsTable.css";
import ExpandedTagColumn from "./ExpandedTagColumn";

const TagsTable = ({ clubs = [] }) => {
  const [expandedCols, setExpandedCols] = useState({});

  const onToggleRow = (id, checked) => {
    setExpandedCols({
      ...expandedCols,
      [id]: checked,
    });
  };
  const renderRows = () => {
    return clubs.map((row, index) => renderHeaderRow(row, index + 1));
  };

  const renderHeaderRow = (teamName, index) => {
    return (
      <li key={index}>
        <input
          type="checkbox"
          id={`list-item-${index}`}
          onChange={(evt) => onToggleRow(index, evt.target.checked)}
        />
        <label
          htmlFor={`list-item-${index}`}
          className={index === 1 ? "first" : ""}
        >
          {teamName}
        </label>
        <ExpandedTagColumn
          teamName={teamName}
          isExpanded={expandedCols[index]}
        />
      </li>
    );
  };

  return (
    <div className="wrapper">
      <ul>{renderRows()}</ul>
    </div>
  );
};

export default TagsTable;
