import React, { useEffect, useState } from 'react';
import {
  getTeamSubCollection,
  createInitialSeason,
  parseDateToFirestore,
  updateSeason
} from '../../helpers/firebase';

const TeamSeason = ({ club, teamName }) => {
  const [season, setSeason] = useState(null);
  const [creatingSeason, setCreatingSeason] = useState(false);
  const [refId, setRefId] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    fetchSeasons();
  }, [club]);

  useEffect(() => {
    if (season && shouldUpdate) {
      updateSeasonData();
    }
  }, [shouldUpdate]);

  const fetchSeasons = async () => {
    await getTeamSubCollection(teamName, club.id)
      .then((subCol) => {
        if (subCol.length > 0) {
          // parse firestore date to js date
          const startDate = formatDate(subCol[0].startDate.toDate());
          const endDate = formatDate(subCol[0].endDate.toDate());

          setSeason({
            startDate,
            endDate
          });
          // keep ID of the season
          setRefId(subCol[0].id);
        }
      })
      .catch((err) => {
        console.log('err', err);
        setCreatingSeason(false);
      });
  };

  const createSeason = async () => {
    setCreatingSeason(true);
    await createInitialSeason(teamName, club.id)
      .then((docId) => {
        fetchSeasons();
      })
      .catch((err) => {
        setCreatingSeason(false);
      });
  };

  const updateSeasonData = async () => {
    const startDate = parseDateToFirestore(season.startDate);
    const endDate = parseDateToFirestore(season.endDate);
    setShouldUpdate(false);
    if (refId) {
      await updateSeason(teamName, club.id, refId, {
        startDate,
        endDate
      })
        .then((docId) => {
          fetchSeasons();
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  return (
    <li>
      {club.data.name}

      {season ? (
        <div className="season-dates">
          <div>
            <p>Start date</p>

            <input
              type="date"
              name="datepicker"
              id="datepicker"
              value={season.startDate}
              onChange={(e) => {
                setSeason({
                  ...season,
                  startDate: e.target.value
                });

                // setShouldUpdate(true);
              }}
            />
          </div>

          <div>
            <p>End date</p>
            <input
              type="date"
              name="datepicker"
              id="datepicker"
              value={season.endDate}
              onChange={(e) => {
                setSeason({
                  ...season,
                  endDate: e.target.value
                });
                setShouldUpdate(true);
              }}
            />
          </div>
        </div>
      ) : (
        // create button
        <button
          className="list-item-12"
          onClick={createSeason}
          disabled={creatingSeason}
        >
          {!creatingSeason ? 'Create Season' : 'Creating...'}
        </button>
      )}
    </li>
  );
};

export default TeamSeason;
