import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../helpers/dateHelpers';
import {
  getPremiumUsers,
  getTeamPlayers,
  updateBatchPremiumStatus
} from '../../helpers/firebase';
import User from './User';

const Teams = ({
  club,
  teamName,
  isExpanded,
  users,
  userToUpdate,
  loading,
  fetchUsers
}) => {
  const { data } = club;
  let initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() + 1);
  const [players, setPlayers] = useState([]);

  const [expand, setExpand] = useState(false);
  const [activePremiumTimeStamp, setActivePremiumTimeStamp] =
    useState(initialDate);
  const [activeBatchPremiumTimeStamp, setActiveBatchPremiumTimeStamp] =
    useState(initialDate);
  const [bouncedTimestamp, setBouncedTimestamp] = useState(initialDate);
  const [isPremium, setIsPremium] = useState(false);
  const coachName = data?.coachName || 'Coach name not provided';
  const coachUser = users.find(
    (user) => user.teamName === data.name && user.userType === 'coach'
  );

  useEffect(() => {
    if (isExpanded) {
      fetchPlayers().then((res) => {});
      if (coachUser && coachUser.premium_until?.seconds) {
        const status =
          new Date().getTime() < coachUser.premium_until.seconds * 1000;
        if (status) {
          setIsPremium(true);
          setActivePremiumTimeStamp(coachUser.premium_until.seconds * 1000);
          setBouncedTimestamp(coachUser.premium_until.seconds * 1000);
        }
      } else {
        setIsPremium(false);
      }
    }

    return () => {
      setPlayers([]);
    };
  }, [isExpanded, users, coachUser]);
  const fetchPlayers = async () => {
    await getTeamPlayers(teamName, club.id)
      .then((data) => {
        setPlayers(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const batchUpdatePremium = async () => {
    const actualUsers = await getPremiumUsers()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return [];
      });

    const updatedPlayers = actualUsers
      .filter((player) =>
        players.some(
          (user) => user.email === player.email || user.id === player.id
        )
      )
      .map((player) => {
        return {
          ...player,
          premium_until: new Timestamp(activeBatchPremiumTimeStamp / 1000, 0)
        };
      });

    await updateBatchPremiumStatus(updatedPlayers);
    await fetchPlayers();
    await fetchUsers?.();
  };

  const renderPlayers = () => {
    return (
      players &&
      players.map((player, index) => {
        let premium =
          users.find(
            (user) => user.email === player.email || user.id === player.id
          ) || false;
        return (
          <User
            player={player}
            premium={premium}
            key={index}
            userToUpdate={userToUpdate}
          />
        );
      })
    );
  };
  return (
    <li>
      <p>{data.name || 'Team name not provided'}</p>
      <p className="paragraph">
        Coach: {coachName}{' '}
        <span
          style={{
            position: 'absolute',
            right: '20px',
            color: 'black',
            cursor: 'pointer'
          }}
          onClick={() => setExpand(!expand)}
        >
          {expand ? '-' : '+'}
        </span>
      </p>
      {isPremium && (
        <p className="paragraph golden">
          Premium until {formatDate(bouncedTimestamp)}
        </p>
      )}

      {expand && (
        <div>
          <p className="paragraph">Set coach as premium</p>
          <input
            type="date"
            name="datepicker"
            id="datepicker"
            value={formatDate(activePremiumTimeStamp, 'y-m-d')}
            onChange={(e) => {
              setActivePremiumTimeStamp(new Date(e.target.value).getTime());
            }}
          />
          <br />
          <span
            className="paragraph golden"
            style={{ marginLeft: '10px', cursor: 'pointer' }}
            onClick={() =>
              userToUpdate(false, activePremiumTimeStamp, coachUser)
            }
          >
            + update coach
          </span>
          {isPremium && (
            <span
              className="paragraph red"
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={() => userToUpdate(false, false, coachUser)}
            >
              - remove coach premium
            </span>
          )}
        </div>
      )}

      {players && players.length > 0 && (
        <React.Fragment>
          <p className="paragraph red">Batch Update Premium</p>
          <input
            type="date"
            name="datepicker"
            id="datepicker"
            value={formatDate(activeBatchPremiumTimeStamp, 'y-m-d')}
            onChange={(e) => {
              setActiveBatchPremiumTimeStamp(
                new Date(e.target.value).getTime()
              );
            }}
          />

          <span
            className="paragraph golden"
            style={{ marginLeft: '10px', cursor: 'pointer' }}
            onClick={batchUpdatePremium}
          >
            + update all players
          </span>
        </React.Fragment>
      )}
      <div>
        <ul>
          {players && players.length > 0 ? (
            renderPlayers()
          ) : (
            <p className="paragraph">No Players</p>
          )}
        </ul>
      </div>
    </li>
  );
};

export default Teams;
