import { parseDateToTimestamp } from './dateHelpers';

export const reportStatsWrapperPlayer = (stats, startDate) => {
  const {
    distanceAmount = 0,
    distanceValue,
    intensityAmount,
    intensityValue = 0,
    liveIntensityAmount = 0,
    runningAmount = 0,
    runningDistance,
    sprintingAmount,
    sprintingDistance,
    walkingAmount = 0,
    walkingDistance,
    hiRunningAmount,
    hiRunningDistance
  } = parseToDecimal(stats);

  const payload = {
    Distance: {
      amount: distanceAmount,
      distance: distanceValue
    },
    Intensity: {
      amount: intensityAmount,
      distance: intensityValue
    },
    LiveIntensity: {
      color: getIntensityColor(liveIntensityAmount),
      liveIntensity: liveIntensityAmount
    },
    Normal: {
      hiRunning: {
        amount: hiRunningAmount,
        distance: hiRunningDistance
      },
      running: {
        amount: runningAmount,
        distance: runningDistance
      },
      sprinting: {
        amount: sprintingAmount,
        distance: sprintingDistance
      },
      walking: {
        amount: walkingAmount,
        distance: walkingDistance
      }
    },
    XccStat: {
      hiAcc: {
        amount: 0,
        distance: 0
      },
      hiDec: {
        amount: 0,
        distance: 0
      },
      lowAcc: {
        amount: 0,
        distance: 0
      },
      lowDec: {
        amount: 0,
        distance: 0
      },
      midAcc: {
        amount: 0,
        distance: 0
      },
      midDec: {
        amount: 0,
        distance: 0
      }
    }
  };

  return {
    drills: [],
    firstHalf: { startTimeStamp: 0, stats: {} },
    secondHalf: { startTimeStamp: 0, stats: {} },
    fullGame: {
      // FIXME: change timestamp to event date
      startTimeStamp: parseDateToTimestamp(startDate),
      stats: payload
    }
  };
};

export const reportStatsWrapperTeam = (statsArr, startDate) => {
  const keys = PLAYER_FORM_FIELDS.map((field) => field.name);

  const stats = {};
  keys.forEach((key) => {
    stats[key] = sumByKey(statsArr, key) / statsArr.length;
  });

  return {
    teamStats: reportStatsWrapperPlayer(stats, startDate),
    teamSeries: reportSeriesWrapper(stats, startDate)
  };
};

export const reportSeriesWrapper = (series, startDate) => {
  const {
    distanceValue,
    intensityAmount,
    runningAmount = 0,
    runningDistance,
    sprintingAmount,
    sprintingDistance,
    walkingAmount = 0,
    walkingDistance,
    hiRunningAmount,
    hiRunningDistance
  } = parseToDecimal(series);

  const payload = {
    acceleration: {
      yLSeries: [0],
      yRSeries: [0],
      yLSeriesHigh: [0],
      yLSeriesLow: [0],
      yLSeriesMid: [0]
    },
    deceleration: {
      yLSeries: [0],
      yRSeries: [0],
      yLSeriesHigh: [0],
      yLSeriesLow: [0],
      yLSeriesMid: [0]
    },
    distances: {
      yLSeries: [distanceValue],
      yRSeries: [distanceValue]
    },
    intensity: {
      yLSeries: [intensityAmount],
      yRSeries: [intensityAmount]
    },
    running: {
      yLSeries: [runningAmount],
      yRSeries: [runningDistance]
    },
    sprints: {
      yLSeries: [sprintingAmount],
      yRSeries: [sprintingDistance]
    },
    technical: {
      yLSeries: [0],
      yRSeries: [0]
    },
    velocity: {
      yLSeries: [0],
      yRSeries: [0]
    },
    walking: {
      yLSeries: [walkingAmount],
      yRSeries: [walkingDistance]
    },
    HI: {
      yLSeries: [hiRunningAmount],
      yRSeries: [hiRunningDistance]
    }
  };

  return {
    drills: [],
    firstHalf: { startTimeStamp: 0, stats: {} },
    secondHalf: { startTimeStamp: 0, stats: {} },
    fullGame: {
      // FIXME: change timestamp to event date
      startTimeStamp: parseDateToTimestamp(startDate),
      stats: payload
    }
  };
};

export const generateGeneralStatsForReport = (
  isMatch,
  startDate,
  totalTime = 0
) => {
  const payload = {
    drills: [],
    isFinal: true,
    isFullReport: true,
    startTimestamp: parseDateToTimestamp(startDate),
    endTimestamp: parseDateToTimestamp(startDate),
    duration: Number(totalTime)
  };

  return payload;
};

// sum value by key
const sumByKey = (arr, key) => {
  return arr.reduce((acc, curr) => {
    // check if its an array
    if (Array.isArray(curr[key])) {
      return acc + parseFloat(curr[key][0]);
    }
    return acc + parseFloat(curr[key]);
  }, 0);
};

// convert minutes to ms
const convertMinutesToMs = (minutes) => {
  return minutes * 60 * 1000;
};

export const PLAYER_FORM_FIELDS = [
  // {
  //   name: 'distanceAmount',
  //   label: 'Distance Amount',
  //   type: 'number',
  //   required: true
  // },
  {
    name: 'distanceValue',
    label: 'Distance Value',
    type: 'number',
    required: true
  },
  {
    name: 'intensityAmount',
    label: 'Intensity Amount',
    type: 'number',
    required: true
  },
  // {
  //   name: 'intensityValue',
  //   label: 'Intensity Value',
  //   type: 'number',
  //   required: true
  // },
  //   {
  //     name: 'liveIntensityAmount',
  //     label: 'Live Intensity Amount',
  //     type: 'number',
  //     required: true
  //   },
  //   {
  //     name: 'runningAmount',
  //     label: 'Running Amount',
  //     type: 'number',
  //     required: true
  //   },
  {
    name: 'runningDistance',
    label: 'Running Distance',
    type: 'number',
    required: true
  },
  {
    name: 'sprintingAmount',
    label: 'Sprinting Amount',
    type: 'number',
    required: true
  },
  {
    name: 'sprintingDistance',
    label: 'Sprinting Distance',
    type: 'number',
    required: true
  },
  //   {
  //     name: 'walkingAmount',
  //     label: 'Walking Amount',
  //     type: 'number',
  //     required: true
  //   },
  {
    name: 'walkingDistance',
    label: 'Walking Distance',
    type: 'number',
    required: true
  },
  {
    name: 'hiRunningAmount',
    label: 'High Intensity Running Amount',
    type: 'number',
    required: true
  },
  {
    name: 'hiRunningDistance',
    label: 'High Intensity Running Distance',
    type: 'number',
    required: true
  }
];

const getIntensityColor = (liveIntensity) => {
  if (liveIntensity > 1.08 && liveIntensity <= 1.33) {
    return '#6AC4F8';
  } else if (liveIntensity > 1.33 && liveIntensity <= 1.58) {
    return '#E1FF58';
  } else if (liveIntensity > 1.58) {
    return '#E5004D';
  }
  return '#CAD6D8';
};

// parse float to 2 decimal places all keys in object
const parseToDecimal = (obj) => {
  return Object.keys(obj).reduce((acc, curr) => {
    // check if its a number and parsefloat
    if (!isNaN(obj[curr])) {
      acc[curr] = Number(obj[curr]);
    } else {
      acc[curr] = obj[curr];
    }

    return acc;
  }, {});
};
