import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Main from './components/Main';
import AppLayout from './components/AppLayout/AppLayout';
import Tags from './components/Tags/Tags';
import PremiumAccounts from './pages/PremiumAccounts';
import ManualEvent from './components/ManualEvent';
import ClubCreation from './components/ClubCreation/';
import ImportPlayers from './components/ImportPlayers';

function App() {
  // Browser router with two routes
  // One for the login screen and one for the main application

  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="main" element={<Main />} />
          <Route path="tags" element={<Tags />} />
          <Route path="premiumAccounts" element={<PremiumAccounts />} />
          <Route path="event" element={<ManualEvent />} />
          <Route path="players" element={<ImportPlayers />} />
          {
            // <Route path="invite-customer" element={<ClubCreation />} />
          }
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
}

export default App;
