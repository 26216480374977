import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../helpers/firebase';
import { logout } from '../../helpers/firebase';
import './AppLayout.css';
import '../Table/Table.css';

const TAB_ROUTES = [
  // {
  //   path: '/invite-customer',
  //   component: 'Invite Customer'
  // },
  {
    path: '/tags',
    component: 'Tags'
  },
  {
    path: '/players',
    component: 'Players'
  },
  {
    path: '/main',
    component: 'Sessions'
  },
  {
    path: '/premiumAccounts',
    component: 'Premium'
  },
  {
    path: '/event',
    component: 'Manual Report'
  }
];

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (window.location.pathname) {
      const route = TAB_ROUTES.find(
        (route) => route.path === window.location.pathname
      );
      if (route) {
        setActive(TAB_ROUTES.indexOf(route));
      }
    }
  }, [window.location.pathname]);

  const signout = () => {
    logout();
  };

  const onLinkPress = (index, path) => {
    setActive(index);
    navigate(path);
  };

  return user ? (
    <div className="layout">
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer'
        }}
      >
        <span onClick={signout} className="logout-btn">
          Logout
        </span>
      </div>

      <div className="header">
        {TAB_ROUTES.map((tab, index) => {
          return (
            <div
              key={index}
              className={`header_btn ${active === index && `btn_active`}`}
              onClick={() => onLinkPress(index, tab.path)}
            >
              {tab.component}
            </div>
          );
        })}
      </div>
      {children}
    </div>
  ) : (
    <div className="layout">{children}</div>
  );
};

export default AppLayout;
