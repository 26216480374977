import React, { useState } from 'react';
import ActiveTeamSelect from '../ActiveTeamSelect';
import ExpandedColumns from './ExpandedColumns';
import './index.css';
const Table = ({ clubs = [] }) => {
  const [expandedCols, setExpandedCols] = useState({});
  const [activeTeam, setActiveTeam] = useState(null);

  const onToggleRow = (id, checked) => {
    setExpandedCols({
      ...expandedCols,
      [id]: checked
    });
  };

  const renderRows = () => {
    return clubs.map((row, index) => renderHeaderRow(row, index + 1));
  };

  const renderHeaderRow = (clubName, index) => {
    return (
      <li key={index}>
        <input
          type="checkbox"
          id={`list-item-${index}`}
          onChange={(evt) => onToggleRow(index, evt.target.checked)}
        />
        <label
          htmlFor={`list-item-${index}`}
          className={index === 1 ? 'first' : ''}
        >
          {clubName}
        </label>
        <ExpandedColumns
          clubName={clubName}
          isExpanded={expandedCols[index]}
          setActiveTeam={(team) => setActiveTeam(team)}
        />
      </li>
    );
  };

  if (activeTeam) {
    return <ActiveTeamSelect activeTeam={activeTeam} />;
  }

  return (
    <div className="wrapper">
      <ul>{renderRows()}</ul>
    </div>
  );
};

export default Table;
