import React, { useEffect, useState } from 'react';
import { auth, loginWithEmailAndPassword } from '../../helpers/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      return navigate('/main');
    }
  }, [user, loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    loginWithEmailAndPassword(email, password);
    // or you can send to backend
  };

  return (
    <div className="login">
      <form className="login__container">
        <input
          type="email"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          type="submit"
          className={`button ${loading && 'button--loading'}`}
          onClick={handleSubmit}
        >
          <span className="button__text">Login</span>
        </button>
      </form>
    </div>
  );
}
export default Login;
