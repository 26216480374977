import React, { useEffect, useState } from 'react';
import { getActiveTeams } from '../../../helpers/firebase';

const ExpandedColumns = ({ clubName, isExpanded, setActiveTeam }) => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setLoading(true);

      getActiveTeams(clubName)
        .then((teams) => {
          setTeams(teams);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isExpanded, clubName]);

  if (loading) {
    return (
      <ul>
        <div>Loading...</div>
      </ul>
    );
  }

  if (teams.length === 0) {
    return (
      <ul className="danger-bg">
        <span>No Teams</span>
      </ul>
    );
  }

  if (!isExpanded) {
    return null;
  }

  return (
    <ul>
      {teams.map((team) => {
        return (
          <p
            key={team.id}
            className="team-name"
            onClick={() =>
              setActiveTeam({
                ...team,
                clubName
              })
            }
          >
            {team.data.name}
          </p>
        );
      })}
    </ul>
  );
};

export default ExpandedColumns;
